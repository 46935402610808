import { tableFiltersUrl } from '../../utils/helper'
import request from '../axiosInterceptor'

const api = {
  getAllUsers(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user/users-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getRoleDetails(id: string | number) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-roles/role-module-permissions?client_role_id=${id}`,
      method: 'get',
    })
  },
  getLoadProfile(email: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user/load-profile?email=${email}`,
      method: 'get',
    })
  },
  getUserDetailsFromId(id: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user/user-details?user_subsidiary_id=${id}`,
      method: 'get',
    })
  },
  postAddUser(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user/add-user`,
      method: 'post',
      data: data,
    })
  },
  putAddUpdateUserStatus(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user/toggle-user-status`,
      method: 'put',
      data: data,
    })
  },
  postAddUserRole(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-roles/add-edit-roles`,
      method: 'post',
      data: data,
    })
  },
  getAllUserRoles(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user/user-roles?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  postBulkUploadUsers(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user/bulk-add-subsidiary-users`,
      method: 'post',
      data: data,
    })
  },
}

export default api
