import { tableFiltersUrl } from '../../utils/helper'
import request from '../axiosInterceptor'

const api = {
  getAllProjects(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/project-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  postAddProject(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/project-details`,
      method: 'post',
      data: { ...data },
    })
  },
  putEditProject(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/project-details`,
      method: 'put',
      data: { ...data },
    })
  },
  getProjectDetails(projectID: string | number) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/project-details?project_details_id=${projectID}`,
      method: 'get',
    })
  },
  getProjectCode(id: string | number) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/workflow/generate-unique-project-code`,
      method: 'get',
    })
  },
  getProjectWorkflows(id: string | number) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/workflow/project-workflow?project_details_id=${id}`,
      method: 'get',
    })
  },
  putEditWorkflows(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${data?.project_details_id}/workflow/project-workflow`,
      method: 'put',
      data: data,
    })
  },
  getTaskTypes(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management$/${id}/workflow/task-types`,
      method: 'get',
    })
  },
  getSeverities(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/workflow/severity`,
      method: 'get',
    })
  },
  getProjectUsersForSchedule(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/project-user-list`,
      method: 'get',
    })
  },
  getProjectPermissionsByProjectID(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/project-permission?project_details_id=${id}`,
      method: 'get',
    })
  },
  getProjectPermissions(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/role/project-permission-list`,
      method: 'get',
    })
  },
  getProjectUsers(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/project-user-list`,
      method: 'get',
    })
  },
  getProjectUsersDetails(id: any, subId: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/project-user?user_subsidiary_id=${subId}`,
      method: 'get',
    })
  },
  getProjectUsersRoles(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/project-role-list`,
      method: 'get',
    })
  },
  getSubsidiaryUsersBySearch(id: any, inputString: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/subsidiary-user-list?input_data=${inputString || ''}`,
      method: 'get',
    })
  },
  postAddSubsidiaryUserToProject(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/project-user`,
      method: 'post',
      data: data,
    })
  },
  putEditSubsidiaryUserToProject(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/project-user`,
      method: 'put',
      data: data,
    })
  },
  getProjectRoles(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/role/project-role-list`,
      method: 'get',
    })
  },
  getProjectRoleDetails(id: any, roleId: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/role/project-role?project_role_id=${roleId}`,
      method: 'get',
    })
  },
  getProjectRoleModules(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/role/project-modules-list`,
      method: 'get',
    })
  },
  postAddRoleToProject(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/role/project-role`,
      method: 'post',
      data: data,
    })
  },
  putEditRoleToProject(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/role/project-role`,
      method: 'put',
      data: data,
    })
  },
  postAddCopilot(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/copilots/project-copilot`,
      method: 'post',
      data: data,
    })
  },
  getProjectCopilots(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/copilots/project-copilot-list`,
      method: 'get',
    })
  },
  getProjectCopilotsRecentQueries(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/copilots/dashboard-recent-query`,
      method: 'get',
    })
  },
  getProjectCopilotsDashboard(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/copilots/dashboard-graph`,
      method: 'get',
    })
  },
  getCopilotDetails(id: any, name: string, platform: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/copilots/project-copilot-details?copilot_name=${name}&platform=${platform}`,
      method: 'get',
    })
  },
  postBulkUploadUsersToProject(data: any, id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/user/bulk-add-project-users`,
      method: 'post',
      data: data,
    })
  },
}

export default api
